import NavBar from '../components/NavBar';

export default function NotFound() {
  return (
    <div className="mil-wrapper">
      <NavBar mode="home" />

      <div className="mil-dark-bg mil-add-page">
        <div className="mil-deco mil-deco-accent" style={{ top: '40vh', right: '10%', transform: 'rotate(90deg)' }}></div>

        <picture>
          <source type="image/webp" srcSet="/img/deco/map.webp" className="mil-map-bg" />
          <source type="image/png" srcSet="/img/deco/map.png" className="mil-map-bg" />
          <img src="/img/deco/map.png" alt="background" className="mil-map-bg" />
        </picture>

        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-4">
              <div className="mil-text-center mil-mb-30">
                <h5 className="mil-light">Error</h5>
                <h1 className="mil-light mil-404-number">404</h1>
                <h5 className="mil-light">Page <span className="mil-accent">not</span> found</h5>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="mil-404-text">
                <p className="mil-light-soft mil-mb-30">
                  The page you are looking for does not exist; it may have been moved or removed altogether.
                </p>

                <a href="/" className="mil-button mil-border mil-light"><span>Back to homepage</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mil-addition-bottom">
        <div className="container-fluid">
          <p className="mil-text-sm mil-light-soft">© Pro Stack LLC 2024</p>
          <p className="mil-text-sm mil-light-soft">All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
}