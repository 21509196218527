import { lazy, Suspense } from 'react';

import { Helmet } from 'react-helmet-async';
import { Typewriter } from 'react-simple-typewriter';

import NavBar from '../components/NavBar';
import Loader from '../components/Loader';

const LazyFooter = lazy(() => import('../components/Footer'));
const LazyReviews = lazy(() => import('../components/Reviews'));
const LazyMixpanel = lazy(() => import('../components/MixPanel'));
const LazyContactForm = lazy(() => import('../components/ContactForm'));
const LazyPartnerList = lazy(() => import('../components/PartnerList'));
const LazyProjectCarousel = lazy(() => import('../components/ProjectCarousel'));

export default function Home() {
  return (
    <div className="mil-wrapper">
      <Suspense>
        <LazyMixpanel name="Home Page" />
      </Suspense>

      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      
      <NavBar mode="home" />

      <div className="mil-banner mil-top-space-0">
        <div className="swiper-container mil-banner-slideshow">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <picture>
                <source type="image/webp" srcSet="/img/photo/de6_opt.webp" className="mil-background-image" />
                <source type="image/png" srcSet="/img/photo/de6_opt.png" className="mil-background-image" />
                <img src="/img/photo/de6_opt.png" alt="background" className="mil-background-image" />
              </picture>
            </div>
          </div>
        </div>

        <div className="mil-overlay"></div>

        <div className="mil-banner-content">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-8">
                <span className="mil-suptitle mil-mb-60">
                  <span className="mil-light">
                    Your Team of &nbsp;
                    <span className="mil-accent typed">
                      <Typewriter 
                        cursorStyle='_'
                        delaySpeed={1000}
                        cursorBlinking={false}
                        words={[ 'Industry Leaders', 'Software Developers', 'Professional Consultants' ]} />

                    </span>
                  </span>
                </span>

                <h1 className="mil-mb-60">
                  <span className="mil-uppercase mil-light">Where code meets</span>
                  <span className="mil-font-3 mil-accent">&nbsp;Craftsmanship</span>
                </h1>

                <div className="mil-flex-hori-center">
                  <div>
                    <a href="/contact" className="mil-button mil-border mil-light">
                      <span>{"Let's Talk"}</span>
                    </a>
                  </div>

                  <p className="mil-button-descr mil-light-soft">
                    Interested in learning more?<br />
                    Contact us today for a free consultation!
                  </p>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="mil-illustration-1">
                  <div className="mil-item mil-item-1">
                    <div className="mil-plus">
                      <div className="mil-hover-window">
                        <div className="mil-window-content">
                          <h5 className="mil-dark mil-mb-15">100% US Based</h5>
                          <div className="mil-divider mil-divider-left mil-mb-15"></div>

                          <p className="mil-text-sm">
                            All of our engineers are based in the United States
                            and pride themselves in the highest quality work
                          </p>
                        </div>
                      </div>

                      <div className="mil-item-hover">
                        <div className="mil-plus-icon">+</div>
                        <h6 className="mil-light">100% US Based</h6>
                      </div>
                    </div>
                  </div>

                  <div className="mil-item mil-item-2">
                    <div className="mil-plus">
                      <div className="mil-hover-window">
                        <div className="mil-window-content">
                          <h5 className="mil-dark mil-mb-15">Highly Experienced</h5>
                          <div className="mil-divider mil-divider-left mil-mb-15"></div>

                          <p className="mil-text-sm">
                            With over 80 years of combined experience under our
                            belts, our team provides extensive industry
                            knowledge
                          </p>
                        </div>
                      </div>

                      <div className="mil-item-hover">
                        <div className="mil-plus-icon">+</div>
                        <h6 className="mil-light">Highly Experienced</h6>
                      </div>
                    </div>
                  </div>

                  <div className="mil-item mil-item-3">
                    <div className="mil-plus">
                      <div className="mil-hover-window">
                        <div className="mil-window-content">
                          <h5 className="mil-dark mil-mb-15">Industry Leaders</h5>
                          <div className="mil-divider mil-divider-left mil-mb-15"></div>

                          <p className="mil-text-sm">
                            Our team has lead software teams and companies of
                            many sizes to deliver quality software products at
                            scale
                          </p>
                        </div>
                      </div>

                      <div className="mil-item-hover">
                        <div className="mil-plus-icon">+</div>
                        <h6 className="mil-light">Industry Leaders</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Suspense fallback={<Loader />}>
        <LazyPartnerList />
      </Suspense>

      <div className="container">
        <div className="mil-divider"></div>
      </div>

      <section className="mil-services mil-p-120-90">
        <div className="mil-deco" style={{ top: 0, right: '20%' }}></div>
        <div className="container">
          <h2 className="mil-mb-30">
            How We Can <span className="mil-accent">Help You</span>
          </h2>

          <div className="row">
            <div className="col-lg-6 col-xl-6">
              <h4 className="mil-mb-60 mil-mt-30">Services</h4>
              <div className="mil-divider mil-divider-left"></div>
              <div className="mil-service-item">
                <div className="mil-service-icon">
                  <div className="mil-icon-frame mil-icon-frame-md">
                    <img src="img/icons/md/7.svg" alt="icon" />
                  </div>
                </div>

                <div className="mil-service-text">
                  <h5 className="mil-mb-30">
                    <span className="mil-accent">01</span> Leadership
                  </h5>
                  <p>
                    With over 80 years combined experience Pro Stack can lead
                    your engineering teams providing Fractional CTO services
                    that help your team perform at its best.
                  </p>
                </div>
              </div>

              <div className="mil-divider mil-divider-left"></div>
              <div className="mil-service-item">
                <div className="mil-service-icon">
                  <div className="mil-icon-frame mil-icon-frame-md">
                    <img src="img/icons/md/1.svg" alt="icon" />
                  </div>
                </div>

                <div className="mil-service-text">
                  <h5 className="mil-mb-30">
                    <span className="mil-accent">02</span> Infrastructure
                  </h5>
                  <p>
                    With experience across all major cloud providers Pro Stack 
                    can help you with migration, scaling, and optimization of your
                    cloud infrastructure.
                  </p>
                </div>
              </div>

              <div className="mil-divider mil-divider-left"></div>
              <div className="mil-service-item">
                <div className="mil-service-icon">
                  <div className="mil-icon-frame mil-icon-frame-md">
                    <img src="img/icons/md/18.svg" alt="icon" />
                  </div>
                </div>

                <div className="mil-service-text">
                  <h5 className="mil-mb-30">
                    <span className="mil-accent">03</span> Backend Development
                  </h5>
                  <p>
                    Pro Stack specializes in crafting high-performance backend
                    solutions that maximize reach and provide a smooth,
                    intuitive interface across all devices.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-xl-6">
              <h4 className="mil-mb-60 mil-mt-30">&nbsp;</h4>

              <div className="mil-divider mil-divider-left"></div>
              <div className="mil-service-item">
                <div className="mil-service-icon">
                  <div className="mil-icon-frame mil-icon-frame-md">
                    <img src="img/icons/md/22.svg" alt="icon" />
                  </div>
                </div>

                <div className="mil-service-text">
                  <h5 className="mil-mb-30">
                    <span className="mil-accent">04</span> Apple Development
                  </h5>
                  <p>
                    Utilizing Apple inspired practices, Pro Stack delivers
                    personalized iOS and macOS applications that drive
                    engagement and offer seamless user experiences.
                  </p>
                </div>
              </div>

              <div className="mil-divider mil-divider-left"></div>
              <div className="mil-service-item">
                <div className="mil-service-icon">
                  <div className="mil-icon-frame mil-icon-frame-md">
                    <img src="img/icons/md/22.svg" alt="icon" />
                  </div>
                </div>

                <div className="mil-service-text">
                  <h5 className="mil-mb-30">
                    <span className="mil-accent">05</span> Android Development
                  </h5>
                  <p>
                    Pro Stack specializes in crafting high-performance Android
                    applications that maximize reach and provide a smooth,
                    intuitive interface across all devices.
                  </p>
                </div>
              </div>

              <div className="mil-divider mil-divider-left"></div>
              <div className="mil-service-item">
                <div className="mil-service-icon">
                  <div className="mil-icon-frame mil-icon-frame-md">
                    <img src="img/icons/md/15.svg" alt="icon" />
                  </div>
                </div>

                <div className="mil-service-text">
                  <h5 className="mil-mb-30">
                    <span className="mil-accent">06</span> Web Development
                  </h5>
                  <p>
                    From responsive websites to complex web applications, Pro
                    Stack offers end-to-end web development services that
                    empower your business to thrive.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="mil-divider"></div>
      </div>

      <Suspense fallback={<Loader />}>
        <LazyProjectCarousel />
      </Suspense>
      
      <section className="mil-how-it-works mil-deep-bg mil-p-120-90">
        <div className="mil-deco" style={{ top: 0, right: '20%' }}></div>
        <div
          className="mil-deco"
          style={{ bottom: 0, left: '30%', transform: 'rotate(180deg)' }}>
          
        </div>

        <div className="container">
          <span className="mil-suptitle mil-suptitle-2 mil-mb-30">
            Discover Our Company
          </span>

          <h2 className="mil-mb-90">
            How We <span className="mil-accent">Collaborate</span> With You
          </h2>

          <div className="row">
            <div className="col-md-6 col-xl-3">
              <div className="mil-mb-60">
                <div className="mil-icon-box-head mil-mb-30">
                  <div className="mil-icon-frame mil-icon-frame-sm">
                    <img src="img/icons/sm/1.svg" alt="icon" />
                  </div>

                  <h5>Thinking Big</h5>
                </div>

                <p>
                  At Pro Stack, we foster a culture of innovation by blending
                  advanced technology with creative thinking and diverse
                  expertise.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <div className="mil-mb-60">
                <div className="mil-icon-box-head mil-mb-30">
                  <div className="mil-icon-frame mil-icon-frame-sm">
                    <img src="img/icons/sm/2.svg" alt="icon" />
                  </div>

                  <h5>Starting Small</h5>
                </div>

                <p>
                  At Pro Stack, we initiate projects with a lean approach,
                  focusing on core functionalities to swiftly deliver value
                  while paving the way for scalable growth.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <div className="mil-mb-60">
                <div className="mil-icon-box-head mil-mb-30">
                  <div className="mil-icon-frame mil-icon-frame-sm">
                    <img src="img/icons/sm/3.svg" alt="icon" />
                  </div>

                  <h5>Creating Fast</h5>
                </div>

                <p>
                  Our experienced team employs cutting-edge agile methodologies
                  and tools to streamline development, ensuring rapid turnaround
                  times
                </p>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <div className="mil-mb-60">
                <div className="mil-icon-box-head mil-mb-30">
                  <div className="mil-icon-frame mil-icon-frame-sm">
                    <img src="img/icons/sm/4.svg" alt="icon" />
                  </div>

                  <h5>Innovating Scale</h5>
                </div>

                <p>
                  At Pro Stack, we craft solutions designed for scalability
                  while anticipating user base growth
                </p>
              </div>
            </div>
          </div>

          {/* <div className="row align-items-center">
            <div className="col-md-6 col-xl-6">
              <a href="#." className="mil-link mil-mb-30">
                <span>Learn More</span><i className="fas fa-arrow-right"></i>
              </a>
            </div>

            <div className="col-md-6 col-xl-6">
              <div className="mil-adaptive-right">
                <a href="#." className="mil-button mil-border mil-mb-30">
                  <span>How We Work</span>
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      
      <section className="mil-skills mil-p-120-90">
        <div className="container">
          <div className="row align-items-end mil-mb-90">
            <div className="col-xl-6">
              <span className="mil-suptitle mil-suptitle-2 mil-mb-30"
                >Our Skills</span
              >
              <h2>
                Business <span className="mil-accent">Success</span> With
                <br />Technology
              </h2>
            </div>

            <div className="col-xl-6">
              <p className="mil-mt-60-adapt">
                As long time industry leaders Pro Stack has a wide variety of
                skills that we could not hope to fit on one page but our
                favorite skills are ones we like to work with the most and ones
                we find ourselves working with most often.
              </p>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-md-6 col-xl-3">
              <h6 className="mil-mb-30">Software Development</h6>

              <div className="mil-skill-frame mil-mb-60">
                <div className="mil-skill-track">
                  <div className="mil-skill-prog" style={{ width: '98%' }}></div>
                </div>

                <div className="mil-text-sm">98%</div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <h6 className="mil-mb-30">Web Development</h6>

              <div className="mil-skill-frame mil-mb-60">
                <div className="mil-skill-track">
                  <div className="mil-skill-prog" style={{ width: '85%' }}></div>
                </div>

                <div className="mil-text-sm">85%</div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <h6 className="mil-mb-30">UX / UI Design</h6>

              <div className="mil-skill-frame mil-mb-60">
                <div className="mil-skill-track">
                  <div className="mil-skill-prog" style={{ width: '65%' }}></div>
                </div>

                <div className="mil-text-sm">65%</div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <h6 className="mil-mb-30">App Development</h6>

              <div className="mil-skill-frame mil-mb-60">
                <div className="mil-skill-track">
                  <div className="mil-skill-prog" style={{ width: '95%' }}></div>
                </div>

                <div className="mil-text-sm">95%</div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <h6 className="mil-mb-30">AR/VR Development</h6>

              <div className="mil-skill-frame mil-mb-60">
                <div className="mil-skill-track">
                  <div className="mil-skill-prog" style={{ width: '75%' }}></div>
                </div>

                <div className="mil-text-sm">75%</div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <h6 className="mil-mb-30">Backend Development</h6>

              <div className="mil-skill-frame mil-mb-60">
                <div className="mil-skill-track">
                  <div className="mil-skill-prog" style={{ width: '95%' }}></div>
                </div>

                <div className="mil-text-sm">95%</div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <h6 className="mil-mb-30">Infrastructure Development</h6>

              <div className="mil-skill-frame mil-mb-60">
                <div className="mil-skill-track">
                  <div className="mil-skill-prog" style={{ width: '95%' }}></div>
                </div>

                <div className="mil-text-sm">95%</div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <h6 className="mil-mb-30">Game Development</h6>

              <div className="mil-skill-frame mil-mb-60">
                <div className="mil-skill-track">
                  <div className="mil-skill-prog" style={{ width: '75%' }}></div>
                </div>

                <div className="mil-text-sm">75%</div>
              </div>
            </div>

            {/* <div className="col-md-6 col-xl-3 mil-text-center">
              <a href="#." className="mil-link mil-mb-30">
                <span>More</span><i className="fas fa-arrow-right"></i>
              </a>
            </div> */}
          </div>
        </div>
      </section>

      <div className="container">
        <div className="mil-divider"></div>
      </div>

      <Suspense fallback={<Loader />}>
        <LazyReviews />
      </Suspense>
      
      <Suspense fallback={<Loader />}>
        <LazyContactForm />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <LazyFooter showSubscribe={false} />
      </Suspense>
    </div>
  );
}
