import { Suspense, lazy, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import BreadcrumbHeader from '../../components/BreadcrumbHeader';
import Loader from '../../components/Loader';
import NavBar from '../../components/NavBar';
import toast, { Toaster } from 'react-hot-toast';

const LazyFooter = lazy(() => import('../../components/Footer'));
const LazyMixpanel = lazy(() => import('../../components/MixPanel'));

export default function Portfolio() {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState<any[]>([]);

  useEffect(() => {
    fetch('/api/projects')
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setProjects(data.projects);
          setLoading(false);

        } else {
          toast.error(`Failed to decode Case Studies`);
        }
        
      })
      .catch((err) => {
        toast.error(`Failed to fetch Case Studies`);
        console.log('Failed to fetch case studies', err);
      });

  }, []);
  
  return (
    <div className="mil-wrapper">
      <Suspense>
        <LazyMixpanel name="Portfolio Page" />
      </Suspense>
      
      <Helmet>
        <title>{`Pro Stack | Case Studies`}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      
      <NavBar mode="page" />
      <BreadcrumbHeader
        title="Case Studies"
        breadcrumbs={[
          { title: "Home", url: "/" },
          { title: "Portfolio", url: "/portfolio" }
        ]} />
      
      <section className="mil-p-120-120">
        <div className="container">

          {(loading) ? (<Loader />) : (
            <>
              {projects.map((project, index) => (
                <div key={index} className="row justify-content-between align-items-center">
                  <div className="col-xl-6 mil-mb-60">
                    <div className="mil-project-cover">
                      <img src={project.keyImage} alt={`${project.name}`} />
                    </div>
                  </div>

                  <div className="col-xl-5 mil-mb-60">
                    <span className="mil-suptitle mil-suptitle-2 mil-mb-30">
                      {project.description.short}
                    </span>

                    <h3 className="mil-mb-30">{project.name}</h3>
                    <a href={`portfolio/${project.link}`} className="mil-button-with-label">
                      <div className="mil-button mil-border mil-icon-button">
                        <span><i className="fas fa-plus"></i></span>
                      </div>

                      <span className="mil-dark">See More</span>
                    </a>
                  </div>
                </div>
              ))}
            </>
          )}

        {/* <div className="mil-divider mil-mb-60"></div>

        <div className="mil-pagination mil-hidden-arrows">
          <div className="mil-slider-nav">
            <div className="mil-slider-btn-prev mil-blog-prev">
              <i className="fas fa-arrow-left"></i><span className="mil-h6">Prev</span>
            </div>
          </div>

          <ul className="mil-pagination-numbers">
            <li className="mil-active"><a href="/portfolio">1</a></li>
            <li><a href="/portfolio">2</a></li>
            <li><a href="/portfolio">3</a></li>
          </ul>

          <div className="mil-slider-nav">
            <div className="mil-slider-btn-next mil-blog-next">
              <span className="mil-h6">Next</span><i className="fas fa-arrow-right"></i>
            </div>
          </div>
        </div> */}
        </div>
      </section>

      <Toaster position="bottom-right" />
      
      <Suspense fallback={<Loader />}>
        <LazyFooter />
      </Suspense>
    </div>
  );
}
