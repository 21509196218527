import { lazy, Suspense, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocalStorage } from '@uidotdev/usehooks';
import toast, { Toaster } from 'react-hot-toast';

import NavBar from '../components/NavBar';
import Loader from '../components/Loader';
import BreadcrumbHeader from '../components/BreadcrumbHeader';
import { Mixpanel } from '../utils/mixpanel';

const LazyFooter = lazy(() => import('../components/Footer'));
const LazyMixpanel = lazy(() => import('../components/MixPanel'));

export default function Blog() {
  const [user, setUser] = useLocalStorage<any>('user', null);
  const [token, setToken] = useLocalStorage('token', null);

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    email: '',
    code: ''
  });

  const handleInputChange = (event: { target: { name: any; value: any; }; }) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleAuth = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    const _data = JSON.stringify(formData);
    const response = await fetch('/api/users/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: _data
    });

    const data = await response.json();

    if (data.result === 'success') {
      setStep(1);

    } else {
      toast.error(data?.message ?? 'An unexpected error has occurred!');
    }
  };

  const handleVerification = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    const _data = JSON.stringify(formData);
    const response = await fetch('/api/users/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: _data
    });

    const data = await response.json();

    if (data.result === 'success') {
      Mixpanel.identify(data.user._id);
      
      setUser(data.user);
      setToken(data.token);
      setFormData({
        email: '',
        code: ''
      });

    } else {
      toast.error(data?.message ?? 'An unexpected error has occurred!');
    }
  };

  const handleLogout = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    setToken(null);
  };

  return (
    <div className="mil-wrapper">
      <Suspense>
        <LazyMixpanel name='Auth Page' />
      </Suspense>

      <Helmet>
        <title>{`Pro Stack | Auth`}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <NavBar mode='page' />
      <BreadcrumbHeader
        title="Authentication"
        breadcrumbs={[
          { title: "Home", url: "/" },
          { title: "Auth", url: "/auth" },
        ]} />

      <section className="mil-contact mil-p-120-0">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-12 col-xl-12 mil-mb-120">
              {(token) ? (
                <>
                  <h3 className="mil-mb-30">Welcome, {user?.name}!</h3>

                  <button type="submit" onClick={handleLogout} className="mil-button mil-border mil-fw">
                    <span>Sign out</span>
                  </button>
                </>

              ) : (
                <form id="formAuth" onSubmit={(step === 0) ? handleAuth : handleVerification}>
                  <h4 className="mil-mb-60">
                    <span className="mil-accent">01.</span> Request Authentication Code
                  </h4>

                  <div className="row">
                    <div className="col-lg-12 mil-mb-30">
                      <div className="mil-input-frame mil-dark-input mil-mb-30">
                        <label className="mil-h6 mil-dark">
                          <span>Email</span>

                          <input
                            type="text"
                            id="email"
                            name="email"
                            autoComplete="email"
                            required
                            placeholder="user@domain.tld"
                            value={formData.email}
                            onChange={handleInputChange} />

                        </label>
                      </div>
                    </div>
                  </div>

                  {(step === 1) ? (
                    <>
                      <h4 className="mil-mb-60">
                        <span className="mil-accent">02.</span> Verify Authentication Code
                      </h4>

                      <div className="row">
                        <div className="col-lg-12 mil-mb-30">
                          <div className="mil-input-frame mil-dark-input mil-mb-30">
                            <label className="mil-h6 mil-dark">
                              <span>Verification Code</span>

                              <input
                                type="text"
                                id="code"
                                name="code"
                                placeholder="000000"
                                value={formData.code}
                                onChange={handleInputChange} />

                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  ): null}

                  <div className="col-lg-12">
                    <button type="submit" className="mil-button mil-border mil-fw">
                      <span>Sign in</span>
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </section>

      <Toaster position='bottom-right' />

      <Suspense fallback={<Loader />}>
        <LazyFooter />
      </Suspense>
    </div>
  );
}
