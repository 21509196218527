import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
  createBrowserRouter

} from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';

import Home from './pages/home';
import Auth from './pages/auth';
import About from './pages/about';
import Contact from './pages/contact';
import Service from './pages/service';

import Team from './pages/team';
import TeamIndividual from './pages/team/individual';

import Portfolio from './pages/portfolio';
import PortfolioIndividual from './pages/portfolio/individual';

import Blog from './pages/blog';
import BlogIndividual from './pages/blog/individual';

import NotFound from './pages/notFound';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import 'react-loading-skeleton/dist/skeleton.css';

import './css/style.css';
import './css/plugins/bootstrap-grid.css';
import './css/plugins/font-awesome.min.css';

const router = createBrowserRouter([
  { path: '/', element: (<Home />) },
  { path: '/auth', element: (<Auth />) },
  { path: '/about', element: (<About />) },
  { path: '/team', element: (<Team />) },
  { path: '/contact', element: (<Contact />) },
  { path: '/services/:slug', element: (<Service />) },
  { path: '/team/:slug', element: (<TeamIndividual />) },
  { path: '/portfolio', element: (<Portfolio />) },
  { path: '/portfolio/:slug', element: (<PortfolioIndividual />) },
  { path: '/blog', element: (<Blog />) },
  { path: '/blog/:slug', element: (<BlogIndividual />) },
  { path: '*', element: (<NotFound />) }
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <HelmetProvider>
    <RouterProvider router={router} />
  </HelmetProvider>
);
