export default function PartnerList() {
  const data = {
    "partners": [
      {
        "name": "techstars",
        "image": {
          "url": "/img/partners/techstars.png",
          "width": 800,
          "height": 143
        }
      },
      {
        "name": "stripe",
        "image": {
          "url": "/img/partners/stripe.png",
          "width": 800,
          "height": 381
        }
      },
      {
        "name": "github",
        "image": {
          "url": "/img/partners/github.png",
          "width": 800,
          "height": 286
        }
      },
      {
        "name": "mongo",
        "image": {
          "url": "/img/partners/mongo.png",
          "width": 800,
          "height": 202
        }
      },
      {
        "name": "cloudflare",
        "image": {
          "url": "/img/partners/cloudflare.png",
          "width": 800,
          "height": 200
        }
      },
      {
        "name": "arduino",
        "image": {
          "url": "/img/partners/arduino.png",
          "width": 800,
          "height": 110
        }
      }
    ]
  };
  
  return (
    <div className="mil-partners mil-p-90-60">
      <div className="container">
        <div className="mil-partners-frame">
          {data.partners.map((partner, index) => (
            <span key={index}>
              <img 
                src={partner.image.url} 
                alt={`${partner.name}`}
                style={{ maxHeight: '50px' }} />

            </span>
          ))}
        </div>
      </div>
    </div>
  )
}