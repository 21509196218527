import { CSSProperties } from 'react';
import Lottie from 'lottie-react';

import pigeon from '../anim/anim_pigeon_opt.json';
// import hamster from '../anim/anim_hamster_opt.json';

interface Props {
  style?: CSSProperties | null
}

export default function Loader({ style = null }: Props) {
  return (
    <div className='loading'>
      <Lottie 
        style={(style) ? style : { maxWidth: '240px', maxHeight: '240px' }}
        className='loading-animation'
        animationData={pigeon} />

    </div>
  )
}