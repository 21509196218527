import { Suspense, lazy, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import NavBar from '../components/NavBar';
import BreadcrumbHeader from '../components/BreadcrumbHeader';
import Loader from '../components/Loader';

const LazyFooter = lazy(() => import('../components/Footer'));
const LazyMixpanel = lazy(() => import('../components/MixPanel'));

export default function Contact() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    company: '',
    role: '',
    category: '',
    message: '',
    budget: ''
  });

  const handleInputChange = (event: { target: { name: any; value: any; }; }) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    const _data = JSON.stringify(formData);
    const response = await fetch('/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: _data
    });

    const data = await response.json();

    if (data.result === 'success') {
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company: '',
        role: '',
        category: '',
        message: '',
        budget: ''
      });

      flashSuccessMessage();

    } else {
      flashErrorMessage();
    }
  };

  const flashSuccessMessage = () => {
    const alert = document.querySelector('.alert-success');

    alert?.classList.remove('alert-hide');
    alert?.classList.add('alert-show');
  };

  const flashErrorMessage = () => {
    const alert = document.querySelector('.alert-error');

    alert?.classList.remove('alert-hide');
    alert?.classList.add('alert-show');
  };

  return (
    <div className="mil-wrapper">
      <Suspense>
        <LazyMixpanel name="Contact Page" />
      </Suspense>
      
      <Helmet>
        <title>{`Pro Stack | Contact`}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      
      <NavBar mode="page" />
      <BreadcrumbHeader
        title="Let's discuss your opportunity"
        breadcrumbs={[
          { title: "Home", url: "/" },
          { title: "Contact Us", url: "/contact" },
        ]} />
      
      <section className="mil-contact mil-p-120-0">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-12 col-xl-12 mil-mb-120">
              <form id="formContact" onSubmit={handleSubmit}>
                <h4 className="mil-mb-60">
                  <span className="mil-accent">01.</span> Tell Us About Yourself
                </h4>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="mil-input-frame mil-dark-input mil-mb-30">
                      <label className="mil-h6 mil-dark">
                        <span>First Name</span>

                        <input
                          type="text"
                          id="first_name"
                          name="first_name"
                          autoComplete="given-name"
                          required
                          placeholder="John"
                          value={formData.first_name}
                          onChange={handleInputChange} />

                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mil-input-frame mil-dark-input mil-mb-30">
                      <label className="mil-h6">
                        <span>Last Name</span>

                        <input
                          type="text"
                          id="last_name"
                          name="last_name"
                          autoComplete="family-name"
                          required
                          placeholder="Jones"
                          value={formData.last_name}
                          onChange={handleInputChange} />

                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mil-input-frame mil-dark-input mil-mb-30">
                      <label className="mil-h6">
                        <span>Email Address</span>

                        <input
                          type="email"
                          id="email"
                          name="email"
                          autoComplete="email"
                          required
                          placeholder="doe@mydomain.com"
                          value={formData.email}
                          onChange={handleInputChange}  />

                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mil-input-frame mil-dark-input mil-mb-30">
                      <label className="mil-h6">
                        <span>Phone</span>

                        <input
                          type="tel"
                          id="phone"
                          name="phone"
                          autoComplete="tel"
                          placeholder="Enter your phone number"
                          value={formData.phone}
                          onChange={handleInputChange} />

                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="mil-input-frame mil-dark-input mil-mb-30">
                      <label className="mil-h6 mil-dark">
                        <span>Company</span>

                        <input
                          type="text"
                          id="company"
                          name="company"
                          autoComplete="organization"
                          placeholder="Your company name"
                          value={formData.company}
                          onChange={handleInputChange} />

                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6 mil-mb-30">
                    <div className="mil-input-frame mil-dark-input mil-mb-30">
                      <label className="mil-h6 mil-dark">
                        <span>Role</span>

                        <input 
                          type="text" 
                          id="role"
                          name="role" 
                          placeholder="Your role"
                          value={formData.role}
                          onChange={handleInputChange} />
                          
                      </label>
                    </div>
                  </div>
                </div>

                <h4 className="mil-mb-60">
                  <span className="mil-accent">02.</span> What Can We Help You With?
                </h4>

                <div className="row">
                  <div className="col-lg-6 mil-mb-30">
                    <div className="mil-input-frame mil-dark-input mil-mb-30">
                      <label className="mil-h6 mil-dark">
                        <span>Project Category</span>

                        <input
                          type="text"
                          id="category"
                          name="category"
                          autoComplete="category"
                          placeholder="Product Design"
                          value={formData.category}
                          onChange={handleInputChange} />

                      </label>
                    </div>
                  </div>
                </div>

                <h4 className="mil-mb-60">
                  <span className="mil-accent">03.</span> Tell Us About Your Project
                </h4>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="mil-input-frame mil-dark-input mil-mb-30">
                      <label className="mil-h6">
                        <span>Project Description</span>

                        <textarea
                          placeholder="Your Message"
                          id="message"
                          name="message"
                          autoComplete="message"
                          className="mil-shortened"
                          value={formData.message}
                          onChange={handleInputChange}>

                        </textarea>
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6 mil-mb-30">
                    <div className="mil-input-frame mil-dark-input mil-mb-30">
                      <label className="mil-h6 mil-dark">
                        <span>Project Budget</span>
                      
                        <input 
                          type="number" 
                          id="budget"
                          name="budget" 
                          placeholder="123"
                          value={formData.budget}
                          onChange={handleInputChange} />
                          
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <button type="submit" className="mil-button mil-border mil-fw">
                      <span>Submit Now</span>
                    </button>
                  </div>
                </div>

                <div className="alert-success" style={{ display: 'none' }}>
                  <h5>Thanks, your message is sent successfully.</h5>
                </div>

                <div className="alert-error" style={{ display: 'none' }}>
                  <h5>Error! Message could not be sent.</h5>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      
      <div>
        <div className="mil-map-frame">
          <iframe
            title='Google Maps'
            width="600"
            height="450"
            style={{ border: 0 }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCAJD9n_C0whHsWVcW7hDVDsGDkS9kr5IA&q=160%20W%20New%20York%20Ave%2C%20Southern%20Pines%2C%20NC%2028387&zoom=15">

          </iframe>
        </div>

        <div className="container"></div>
      </div>

      <section className="mil-p-120-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="mil-mb-60">
                <h4 className="mil-mb-30">United States</h4>
                <h5 className="mil-list-title mil-mb-15">North Carolina</h5>
                <p className="mil-mb-30">
                  160 W New York Ave Unit 3<br />Southern Pines, NC 28387
                </p>

                <div className="mil-divider mil-divider-left mil-mb-30"></div>

                <h6 className="mil-mb-15">
                  <span className="mil-accent">+1</span> (720) 340-1672
                </h6>

                <h6 className="mil-mb-15">
                  <span className="mil-accent">team</span>@prostackdev.com
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Suspense fallback={<Loader />}>
        <LazyFooter showSubscribe={false} />
      </Suspense>
    </div>
  );
}
