import { lazy, Suspense, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { format } from 'date-fns';

import NavBar from '../../components/NavBar';
import Loader from '../../components/Loader';

const LazyFooter = lazy(() => import('../../components/Footer'));
const LazyMixpanel = lazy(() => import('../../components/MixPanel'));
const LazyMarkdown = lazy(() => import('../../components/MarkdownRenderer'));

export default function BlogIndividual() {
  const { slug } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState<any>({});

  useEffect(() => {
    fetch(`/api/blog/search?slug=${slug}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setPost(data.posts[0]);
          
        } else {
          navigate('/404');
        }

        setLoading(false);
      })
      .catch((_) => {
        navigate('/404');
        setLoading(false);
      });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;
    const input = form.querySelector('input') as HTMLInputElement;
    const search = input.value;

    navigate(`/blog?search=${search}`);
  };
  
  return (
    <div className="mil-wrapper">
      {(post) ? (
        <>
          <Suspense>
            <LazyMixpanel name={`Blog Post, ${post.title}`} />
          </Suspense>
          
          <Helmet>
            <title>{`Pro Stack | Blog | ${post.title}`}</title>
            <link rel="canonical" href={window.location.href} />
          </Helmet>
        </>

      ) : null}

      <NavBar mode='home' />
      
      <div className="mil-banner-sm-2 mil-deep-bg">
        {(loading) ? null : (
          <img src={post?.headerImage} className="mil-background-image" style={{ objectPosition: 'center' }} alt="Publication cover" />
        )}

        <div className="mil-overlay"></div>
      </div>
      
      <section className="mil-blog mil-p-120-0">
        <div className="container">
          <div className="row justify-content-between">
            {(loading) ? (<Loader />) : (
              <div className="col-lg-8 col-xl-8 mil-mb-120">
                <h3 className="mil-up-font mil-mb-30">{post?.title}</h3>
                <ul className="mil-dot-list mil-post-info mil-text-sm mil-mb-60">
                  <li className="mil-post-author">
                    <img src={post?.author?.image} alt="Author" />
                    <span>{post?.author?.name}</span>
                  </li>

                  <li>{format(new Date(post?.createdAt), 'dd MMM. yyyy')}</li>
                  <li>11 min read</li>
                </ul>

                <div className="mil-divider mil-mb-60"></div>

                <Suspense fallback={<Loader />}>
                  <LazyMarkdown content={post?.content} />
                </Suspense>

                <ul className="mil-tags mil-mb-60">
                  <li className="mil-h6">Tags:&nbsp;&nbsp; </li>
                  {post?.tags?.map((tag: string, index: number) => (
                    <li key={index}><a href="#.">{tag}</a></li>
                  ))}
                </ul>
              </div>
            )}

            {/* Sidebar */}
            <div className="col-lg-4 col-xl-3 mil-mb-120">
              <div className="mil-mb-60">
                {(loading) ? <Loader /> : (
                  <>
                    <h5 className="mil-list-title mil-mb-30">About the Author</h5>
                    <p className="mil-mb-30">
                      {post?.author?.bio?.summary}
                    </p>

                    <a href={`/team/${post?.author?.link}`} className="mil-post-sm mil-mb-15">
                      <div className="mil-cover-frame">
                        <img src={post?.author?.image} alt="cover" />
                      </div>

                      <div className="mil-description">
                        <h4 className="mil-font-3 mil-accent">{post?.author?.name}</h4>
                        <p className="mil-text-sm">{post?.author?.title}</p>
                      </div>
                    </a>
                  </>
                )}
              </div>

              <div className="mil-divider mil-mb-60"></div>

              <div className="mil-mb-60">
                <h5 className="mil-list-title mil-mb-30">Search</h5>

                <form className="mil-sidebar-input-frame mil-mb-60" onSubmit={handleSearch}>
                  <input type="text" className="mil-sidebar-input" placeholder="Search here..." />
                  <button type="submit"><i className="fas fa-search"></i></button>
                </form>
              </div>

              <div className="mil-divider mil-mb-60"></div>

              <div className="mil-mb-60">
                <h5 className="mil-list-title mil-mb-30">Categories</h5>

                <ul className="mil-hover-link-list">
                  {post?.categories?.map((category: string, index: number) => (
                    <li key={index}><a href={`/blog?category=${category}`}>{category}</a></li>
                  ))}
                </ul>
              </div>

              <div className="mil-divider mil-mb-60"></div>

              <div className="mil-mb-60">
                <h5 className="mil-list-title mil-mb-30">Tags</h5>

                <ul className="mil-tags">
                  {post?.tags?.map((tag: string, index: number) => (
                    <li key={index}><a href={`/blog?tag=${tag}`}>{tag}</a></li>
                  ))}
                </ul>
              </div>

              <div className="mil-divider mil-mb-60"></div>

              <h5 className="mil-list-title mil-mb-30">Contact us</h5>
              <p className="mil-mb-30">
                Interested in learning more?<br />
                Contact us today for a free consultation!
              </p>

              <a href="/contact" className="mil-link mil-link-sm"><span>Contact</span><i className="fas fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </section>

      <Suspense fallback={<Loader />}>
        <LazyFooter />
      </Suspense>
    </div>
  );
}
