import { useEffect, useState } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';

interface Props {
  mode: 'home' | 'page'
}

export default function NavBar({ mode }: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [token] = useLocalStorage('token', null);
  
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);

  }, []);

  return (
    <div className="mil-top-position mil-fixed">
      <div className={
        (mode === 'home') ? 
          (offset >= 220) ? 
            "mil-top-panel mil-animated" : 
            "mil-top-panel mil-top-panel-transparent mil-animated" : 
          "mil-top-panel"
      }>

        <div className="container">
          <a aria-label="Home" href="/" className="mil-logo" style={{ width: '140px' }}></a>

          <div className={isMenuOpen ? "mil-navigation mil-active" : "mil-navigation"}>
            <nav>
              <ul>
                <li><a href="/about">About</a></li>
                <li><a href="/team">Team</a></li>
                <li><a href="/portfolio">Case Studies</a></li>

                <li className="mil-has-children">
                  <a href="#.">Services</a>
                  <ul>
                    <li><a href="/services/leadership">Leadership</a></li>
                    <li><a href="/services/infrastructure">Infrastructure</a></li>
                    <li><a href="/services/backend">Backend</a></li>
                    <li><a href="/services/apple">Apple</a></li>
                    <li><a href="/services/android">Android</a></li>
                    <li><a href="/services/web">Web</a></li>
                  </ul>
                </li>

                {(token) ? (
                  <>
                    <li><a href="/blog">Blog</a></li>
                  </>
                  
                ) : null}

                <li><a href="/contact">Contact Us</a></li>
              </ul>
            </nav>
          </div>

          <div className={isMenuOpen ? "mil-menu-btn mil-active" : "mil-menu-btn"} onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  )
}
