import { Suspense, lazy, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Loader from '../../components/Loader';
import NavBar from '../../components/NavBar';

const LazyFooter = lazy(() => import('../../components/Footer'));
const LazyMixpanel = lazy(() => import('../../components/MixPanel'));
const LazyContactForm = lazy(() => import('../../components/ContactForm'));

export default function TeamIndividual() {
  const { slug } = useParams();
  const navigate = useNavigate();

  const [, setLoading] = useState(true);
  const [person, setPerson] = useState<any>({});

  useEffect(() => {
    fetch(`/api/users/team?search=${slug}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setPerson(data.team[0]);
          
        } else {
          navigate('/404');
        }

        setLoading(false);
      })
      .catch((_) => {
        navigate('/404');
        setLoading(false);
      });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mil-wrapper">
      {(person) ? (
        <>
          <Suspense>
            <LazyMixpanel name={`Team Page, ${person.name}`} />
          </Suspense>
          
          <Helmet>
            <title>{`Pro Stack | ${person.name}`}</title>
            <link rel="canonical" href={window.location.href} />
          </Helmet>
        </>

      ) : null}

      <NavBar mode='page' />

      <div className="mil-banner-sm mil-deep-bg">
        <picture>
          <source type="image/webp" srcSet="/img/deco/map.webp" className="mil-background-image" />
          <source type="image/png" srcSet="/img/deco/map.png" className="mil-background-image" />
          <img src="/img/deco/map.png" alt="background" className="mil-background-image" />
        </picture>

        <div className="mil-banner-content">
          <div className="container mil-relative">
            <div className="row justify-content-between">
              <div className="col-lg-6 mil-adaptive-center">
                <a href="/team" className="mil-link link-left mil-mb-30">
                  <i className="fas fa-arrow-left"></i>
                  <span>Go Back</span>
                </a>

                <h2 className="mil-uppercase mil-mb-30">{person.name}</h2>
                <p>{person.title}, {person.state}</p>
              </div>
            </div>

            <div className="mil-portrait-position">
              <div className="mil-portrait-frame">
                {(person.image) ? (
                  <img src={person?.image} alt={`${person?.name}`} />

                ) : (
                  <Loader 
                    style={{ 
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      objectFit: 'cover',
                      objectPosition: 'top'
                    }} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <section className="mil-team mil-p-120-120">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-8 col-lg-8 col-xl-8">
              <h3 className="mil-mb-30">Biography</h3>
              <p className="mil-mb-60">
                {person?.bio?.summary}
              </p>

              <ul className="mil-timeline">
                {person?.timeline?.map((item: any, index: any) => (
                  <li key={index}>
                    <h5 className="mil-mb-5">{item.title}</h5>
                    <p className="mil-text-sm">{item.start}-{item.finish}</p>
                  </li>
                ))}
              </ul>

              <p className="mil-mb-60">
                {person?.bio?.details}
              </p>

              <div className="mil-divider mil-mb-60"></div>
              <div className="row">
                {person?.skills?.list.map((skill: any, index: any) => (
                  <div className="col-lg-4" key={index}>
                    <h6 className="mil-mb-30">{skill.name}</h6>

                    <div className="mil-skill-frame mil-mb-60">
                      <div className="mil-skill-track">
                        <div className="mil-skill-prog" style={{ width: `${skill.percent}%` }}></div>
                      </div>

                      <div className="mil-text-sm">{skill.percent}%</div>
                    </div>
                  </div>
                
                ))}
              </div>

              <div className="mil-divider mil-mb-60"></div>
              <h3 className="mil-mb-30">Skills</h3>
              <p className="mil-mb-60">
                {person?.skills?.summary}
              </p>

              <div className="mil-divider mil-mb-60"></div>
              <h3 className="mil-mb-60">Social</h3>
              <ul className="mil-social-links mil-mb-30">
                {person?.social?.map((link: any, index: any) => (
                  <li key={index}>
                    <a href={link.url} target="_blank" rel="noreferrer">{link.name}</a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-md-3 col-lg-3 col-lg-3">
              <div className="mil-icon-box mil-mt-60-adapt">
                <div className="mil-icon-frame mil-icon-frame-md mil-mb-30">
                  <img src="/img/icons/md/7.svg" alt="icon" />
                </div>

                <h4 className="mil-mb-30">
                  {person?.quote?.content}
                </h4>
                <div className="mil-divider mil-divider-left mil-mb-30"></div>
                <p>{person?.quote?.author}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Suspense fallback={<Loader />}>
        <LazyContactForm />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <LazyFooter />
      </Suspense>
    </div>
  )
}
