import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';

import PartnerList from '../components/PartnerList';
import BreadcrumbHeader from '../components/BreadcrumbHeader';
import NavBar from '../components/NavBar';
import Loader from '../components/Loader';

const LazyFooter = lazy(() => import('../components/Footer'));
const LazyMixpanel = lazy(() => import('../components/MixPanel'));

export default function About() {
  return (
    <div className="mil-wrapper">
      <Suspense>
        <LazyMixpanel name="About Page" />
      </Suspense>
      
      <Helmet>
        <title>{`Pro Stack | About`}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <NavBar mode="page" />
      <BreadcrumbHeader 
        title="About Pro Stack"
        breadcrumbs={[
          { title: "Home", url: "/" },
          { title: "About Us", url: "/" }
        ]} />
      
      <section className="mil-deep-bg mil-p-120-60">
        <div className="mil-deco" style={{ top: 0, left: '35%' }}></div>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-5 mil-mb-60">
              <div className="mil-circle-illustration">
                <div className="mil-circle-bg"></div>
                <div className="mil-image-frame">
                  <img src="img/faces/arcodia.jpeg" alt="img" />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <span className="mil-suptitle mil-suptitle-2 mil-mb-30">Firm Overview</span>
              
              <h2 className="mil-mb-50">
                At Pro Stack, we Specialize in <br />
                <span className="mil-accent">turning even the most complex innovative ideas</span>
                &nbsp;into an Instant Reality
              </h2>

              <p className="mil-mb-50">
                Our team, a blend of seasoned engineers and visionary thinkers,
                possesses an unwavering drive and passion not only for
                developing cutting-edge technology but also for fostering
                strong, lasting relationships with our clients. This dual focus
                ensures that we not only meet the technical demands of a project
                but also deeply understand and align with our clients visions
                and goals.
              </p>

              <div className="row align-items-end">
                <div className="col-xl-7">
                  <ul className="mil-check-icon-list mil-mb-60">
                    <li>
                      <img src="img/icons/sm/12.svg" alt="icon" />
                      <span className="mil-dark">
                        Where innovation meets execution
                      </span>
                    </li>

                    <li>
                      <img src="img/icons/sm/12.svg" alt="icon" />
                      <span className="mil-dark">
                        Redefining the boundaries of technology
                      </span>
                    </li>

                    <li>
                      <img src="img/icons/sm/12.svg" alt="icon" />
                      <span className="mil-dark">
                        Challenge and boundary driven
                      </span>
                    </li>

                    <li>
                      <img src="img/icons/sm/12.svg" alt="icon" />
                      <span className="mil-dark">
                        360 Professionals
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="col-xl-5">
                  <a href="team/arcodia" className="mil-post-sm mil-mb-60">
                    <div className="mil-cover-frame">
                      <img src="img/faces/arcodia.jpeg" alt="cover" />
                    </div>

                    <div className="mil-description">
                      <h4 className="mil-font-3 mil-accent">Charlie Arcodia</h4>
                      <p className="mil-text-sm">Founding Member</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="mil-p-120-60">
        <div className="mil-deco" style={{ top: 0, left: '25%' }}></div>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-5">
              <div className="mil-h1">80<span className="mil-accent">+</span></div>
              <h6 className="mil-mb-60">Years Experience</h6>

              <h2 className="mil-mb-60">
                We Run All Kinds of IT Services That Vow Your Success
              </h2>
            </div>

            <div className="col-lg-6">
              <h3 className="mil-mb-60">
                Accelerate <span className="mil-accent">Innovation</span> with world
                class talent. We’ll match you perfectly.
              </h3>

              <div className="row">
                <div className="col-lg-6">
                  <h6 className="mil-mb-30">
                    <span className="mil-accent">13+</span>&nbsp; Apple Development
                  </h6>
                </div>

                <div className="col-lg-6">
                  <h6 className="mil-mb-30">
                    <span className="mil-accent">11+</span>&nbsp; Android Development
                  </h6>
                </div>

                <div className="col-lg-6">
                  <h6 className="mil-mb-30">
                    <span className="mil-accent">20+</span>&nbsp; Web Development
                  </h6>
                </div>

                <div className="col-lg-6">
                  <h6 className="mil-mb-30">
                    <span className="mil-accent">8+</span>&nbsp; Consulting
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="mil-divider"></div>
      </div>

      <PartnerList />
      
      <section className="mil-deep-bg mil-p-120-60">
        <div className="mil-deco" style={{ top: 0, right: '15%' }}></div>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-5 mil-mb-60">
              <div className="mil-circle-illustration">
                <div className="mil-circle-bg"></div>
                <div className="mil-image-frame">
                  <img src="img/faces/solberg.jpg" alt="img" />
                </div>
              </div>
            </div>
            
            <div className="col-lg-6 mil-mb-60">
              <span className="mil-suptitle mil-suptitle-2 mil-mb-30">Excellence</span>
              <h2 className="mil-mb-50">{"Pro Stack's Mission"}</h2>

              <p className="mil-mb-50">
                {"At Pro Stack, we're dedicated to transforming ambitious ideas into reality through cutting-edge technology and innovative solutions. Rooted in our incubator heritage, our mission is to drive progress with passion, agility, and a commitment to excellence, while building lasting relationships that empower our clients to succeed in a dynamic world."}
              </p>

              <ul className="mil-simple-list">
                <li>Innovating solutions, empowering success</li>
                <li>Techstars legacy, future-focused execution</li>
                <li>Passion drives us; excellence defines us</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      
      <section className="mil-p-120-120">
        <div className="container">
          <span className="mil-suptitle mil-suptitle-2 mil-mb-30">
            Discover Our Company
          </span>

          <h2 className="mil-mb-120">Why Work With Us</h2>

          <div className="mil-divider"></div>
          <div className="mil-line-icon-box">
            <div className="row align-items-center">
              <div className="col-xl-2">
                <div className="mil-icon-frame mil-icon-frame-md mil-mb-30">
                  <img src="img/icons/md/6.svg" alt="icon" />
                </div>
              </div>

              <div className="col-xl-4">
                <h4 className="mil-mb-30">Top Expertise</h4>
              </div>

              <div className="col-xl-6">
                <p className="mil-box-text mil-mb-30">
                  Pro Stack boasts a team of experts, renowned for their mastery
                  in leveraging the latest technological advancements and
                  innovative strategies. Our top-tier talent pool excels in
                  transforming complex challenges into groundbreaking solutions.
                </p>
              </div>
            </div>
          </div>

          <div className="mil-divider"></div>
          <div className="mil-line-icon-box">
            <div className="row align-items-center">
              <div className="col-xl-2">
                <div className="mil-icon-frame mil-icon-frame-md mil-mb-30">
                  <img src="img/icons/md/10.svg" alt="icon" />
                </div>
              </div>

              <div className="col-xl-4">
                <h4 className="mil-mb-30">Quality Management</h4>
              </div>

              <div className="col-xl-6">
                <p className="mil-box-text mil-mb-30">
                  At Pro Stack, every project undergoes rigorous quality
                  management, guaranteeing that we not only meet but exceed the
                  highest standards of excellence.
                </p>
              </div>
            </div>
          </div>

          <div className="mil-divider"></div>

          <div className="mil-line-icon-box">
            <div className="row align-items-center">
              <div className="col-xl-2">
                <div className="mil-icon-frame mil-icon-frame-md mil-mb-30">
                  <img src="img/icons/md/2.svg" alt="icon" />
                </div>
              </div>
              
              <div className="col-xl-4">
                <h4 className="mil-mb-30">Utmost Flexibility</h4>
              </div>

              <div className="col-xl-6">
                <p className="mil-box-text mil-mb-30">
                  Our utmost flexibility ensures that Pro Stack can tailor
                  solutions to fit any challenge, seamlessly adapting to the
                  evolving needs of our clients.
                </p>
              </div>
            </div>
          </div>

          <div className="mil-divider"></div>
          <div className="mil-line-icon-box">
            <div className="row align-items-center">
              <div className="col-xl-2">
                <div className="mil-icon-frame mil-icon-frame-md mil-mb-30">
                  <img src="img/icons/md/4.svg" alt="icon" />
                </div>
              </div>

              <div className="col-xl-4">
                <h4 className="mil-mb-30">Agility</h4>
              </div>

              <div className="col-xl-6">
                <p className="mil-box-text mil-mb-30">
                  {"Pro Stack's agility in project execution allows for rapid development and adaptation, turning quick iterations and client feedback into superior end products."}
                </p>
              </div>
            </div>
          </div>

          <div className="mil-divider"></div>
          <div className="mil-line-icon-box">
            <div className="row align-items-center">
              <div className="col-xl-2">
                <div className="mil-icon-frame mil-icon-frame-md mil-mb-30">
                  <img src="img/icons/md/5.svg" alt="icon" />
                </div>
              </div>

              <div className="col-xl-4">
                <h4 className="mil-mb-30">Innovation</h4>
              </div>

              <div className="col-xl-6">
                <p className="mil-box-text mil-mb-30">
                  Innovation is at the heart of Pro Stack, where we leverage
                  cutting-edge technologies and creative thinking to solve
                  complex problems and deliver novel solutions.
                </p>
              </div>
            </div>
          </div>

          <div className="mil-divider"></div>
        </div>
      </section>
      
      <section className="mil-deep-bg mil-p-120-60">
        <div className="mil-deco" style={{ top: 0, right: '25%' }}></div>
        <div className="container">
          <span className="mil-suptitle mil-suptitle-2 mil-mb-30">
            Core Values
          </span>

          <h2 className="mil-mb-120">
            We Live by <span className="mil-accent">Powerful</span> Values
          </h2>

          <div className="row">
            <div className="col-md-6 col-xl-4">
              <div className="mil-icon-box-2 mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-md mil-icon-bg mil-mb-30">
                  <img src="img/icons/md/10.svg" alt="icon" />
                </div>

                <div className="mil-box-text">
                  <h4 className="mil-mb-30">We Are</h4>
                  <p className="mil-box-text">
                    {"Pro Stack is a collective of visionary technologists and innovators, dedicated to pushing the boundaries of what's possible with technology."}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-4">
              <div className="mil-icon-box-2 mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-md mil-icon-bg mil-mb-30">
                  <img src="img/icons/md/10.svg" alt="icon" />
                </div>

                <div className="mil-box-text">
                  <h4 className="mil-mb-30">We Deep Dive</h4>
                  <p className="mil-box-text">
                    We immerse ourselves in every challenge, ensuring a thorough
                    understanding and innovative approach to crafting tailored
                    solutions.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-4">
              <div className="mil-icon-box-2 mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-md mil-icon-bg mil-mb-30">
                  <img src="img/icons/md/10.svg" alt="icon" />
                </div>

                <div className="mil-box-text">
                  <h4 className="mil-mb-30">We Take</h4>
                  <p className="mil-box-text">
                    At Pro Stack, we take immense pride in our work, striving
                    for excellence in every project to exceed expectations.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-4">
              <div className="mil-icon-box-2 mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-md mil-icon-bg mil-mb-30">
                  <img src="img/icons/md/10.svg" alt="icon" />
                </div>

                <div className="mil-box-text">
                  <h4 className="mil-mb-30">We Value</h4>
                  <p className="mil-box-text">
                    Our clients are our priority; we value their vision, working
                    closely with them to transform their ideas into impactful
                    technological solutions.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-4">
              <div className="mil-icon-box-2 mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-md mil-icon-bg mil-mb-30">
                  <img src="img/icons/md/10.svg" alt="icon" />
                </div>

                <div className="mil-box-text">
                  <h4 className="mil-mb-30">We Believe</h4>
                  <p className="mil-box-text">
                    {"We have unwavering faith in our team's abilities and potential, driven by our shared commitment to innovation and success."}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-4">
              <div className="mil-icon-box-2 mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-md mil-icon-bg mil-mb-30">
                  <img src="img/icons/md/10.svg" alt="icon" />
                </div>

                <div className="mil-box-text">
                  <h4 className="mil-mb-30">We Say “We”</h4>
                  <p className="mil-box-text">
                    {`"We" encapsulates our ethos; at Pro Stack, our strength lies in our unity and collaborative spirit, making us a formidable force in the tech industry.`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Suspense fallback={<Loader />}>
        <LazyFooter />
      </Suspense>
    </div>
  );
}
