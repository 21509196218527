import { lazy, Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

import NavBar from '../../components/NavBar';
import Loader from '../../components/Loader';
import BreadcrumbHeader from '../../components/BreadcrumbHeader';
import toast, { Toaster } from 'react-hot-toast';

const LazyFooter = lazy(() => import('../../components/Footer'));
const LazyMixpanel = lazy(() => import('../../components/MixPanel'));

type LoadOverrides = {
  tag?: string;
  search?: string;
  category?: string;
};

export default function Blog() {
  const chunkSize = 5;

  const [searchParams, setSearchParams] = useSearchParams();
  
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    loadMore();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = (overrides: LoadOverrides = {}) => {
    setLoading(true);
  
    let url = `/api/blog/search?limit=${chunkSize}&skip=${page * chunkSize}`;

    const tag = overrides.tag || searchParams.get('tag');
    const search = overrides.search || searchParams.get('search');
    const category = overrides.category || searchParams.get('category');

    if ((search) && (search.trim() !== '')) {
      url += `&search=${search}`;

    } else if ((tag) && (tag.trim() !== '')) {
      url += `&tag=${tag}`;

    } else if ((category) && (category.trim() !== '')) {
      url += `&category=${category}`;
    }

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setTotal(data.total);
          setPosts(data.posts); 
          setLoading(false);

        } else {
          toast.error(`Failed to decode Blog`);
        }

      })
      .catch((err) => {
        toast.error(`Failed to fetch Blog`);
        console.log('Failed to fetch blog', err);
      });
  };

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.currentTarget as HTMLFormElement;
    const input = form.querySelector('input') as HTMLInputElement;
    const search = input.value;

    setLoading(true);
    setSearchParams({ search });
    setPage(0);
    setTotal(0);
    setPosts([]);
    loadMore({ search });
  };
  
  return (
    <div className="mil-wrapper">
      <Suspense>
        <LazyMixpanel name='Blog Page' />
      </Suspense>

      <Helmet>
        <title>{`Pro Stack | Blog`}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <NavBar mode='page' />
      <BreadcrumbHeader
        title='Our Blog'
        breadcrumbs={[
          { title: 'Home', url: '/' },
          { title: 'Blog', url: '/blog' }
        ]} />

      <section className="mil-blog mil-p-120-0">
        <div className="container">
          <div className="row justify-content-between">
            {/* Main Content */}
            <div className="col-lg-8 col-xl-8 mil-mb-120">
              <h4 className="mil-mb-60">
                {/* This is a mess.. */}
                {(searchParams.get('search')) ? (
                  <>
                    Search: <span className="mil-accent">{`${searchParams.get('search')}`}</span>
                  </>

                ) : null}

                {(searchParams.get('tag')) ? (
                  <>
                    Browsing Tag: <span className="mil-accent">{`${searchParams.get('tag')}`}</span>
                  </>
                
                ) : null}

                {(searchParams.get('category')) ? (
                  <>
                    Browsing Category: <span className="mil-accent">{`${searchParams.get('category')}`}</span>
                  </>
                
                ) : null}

                {(!searchParams.get('search') && !searchParams.get('tag') && !searchParams.get('category')) ? (<>Recent Posts</>) : null}
              </h4>

              {(loading) ? (<Loader />) : (
                <>
                  {posts.map((post, index) => (
                    <a key={index} href={`/blog/${post.slug}`} className="mil-card mil-mb-60">
                      <div className="mil-cover-frame">
                        <img src={post.headerImage} alt="project" />
                      </div>

                      <div className="mil-description">
                        <div className="mil-card-title">
                          <ul className="mil-dot-list mil-text-sm mil-mb-15">
                            <li>{post.categories[0]}</li>
                            <li>{format(new Date(post.createdAt), 'dd MMM. yyyy')}</li>
                          </ul>

                          <h4>{post.title}</h4>
                        </div>

                        <div className="mil-card-text">
                          <p>{post.excerpt}</p>
                        </div>
                      </div>
                    </a>
                  ))}
                </>
              )}

              <div className="mil-divider mil-mb-60"></div>

              <div className="mil-pagination mil-hidden-arrows">
                <div className="mil-slider-nav">
                  {page > 0 ? (
                    <div className="mil-slider-btn-prev mil-blog-prev" onClick={() => { setPage(page - 1); loadMore(); }}>
                      <i className="fas fa-arrow-left"></i>
                      <span className="mil-h6">Prev</span>
                    </div>

                  ) : (
                    <div className="mil-slider-btn-prev mil-blog-prev swiper-button-disabled">
                      <i className="fas fa-arrow-left"></i>
                      <span className="mil-h6">Prev</span>
                    </div>
                  )}
                </div>

                <ul className="mil-pagination-numbers">
                  {Array.from({ length: Math.ceil(total / chunkSize) }, (_, i) => (
                    <li key={i} className={(i === page) ? 'mil-active' : ''}>
                      <a href="#." onClick={() => { setPage(i); loadMore(); }}>{i + 1}</a>
                    </li>
                  ))}
                </ul>

                <div className="mil-slider-nav">
                  {(page + 1) * chunkSize < total ? (
                    <div className="mil-slider-btn-next mil-blog-next" onClick={() => { setPage(page + 1); loadMore(); }}>
                      <span className="mil-h6">Next</span>
                      <i className="fas fa-arrow-right"></i>
                    </div>

                  ) : (
                    <div className="mil-slider-btn-next mil-blog-next swiper-button-disabled">
                      <span className="mil-h6">Next</span>
                      <i className="fas fa-arrow-right"></i>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Sidebar */}
            <div className="col-lg-4 col-xl-3 mil-mb-120">
              <div className="mil-mb-60">
                <h5 className="mil-list-title mil-mb-30">Search</h5>

                <form className="mil-sidebar-input-frame mil-mb-60" onSubmit={handleSearch}>
                  <input type="text" className="mil-sidebar-input" placeholder="Search here..." />
                  <button type="submit"><i className="fas fa-search"></i></button>
                </form>
              </div>

              <div className="mil-divider mil-mb-60"></div>

              <div className="mil-mb-60">
                <h5 className="mil-list-title mil-mb-30">Categories</h5>
                
                <ul className="mil-hover-link-list">
                  <li><a href="#.">Business</a></li>
                  <li><a href="#.">Design</a></li>
                  <li><a href="#.">Development</a></li>
                  <li><a href="#.">Management</a></li>
                  <li><a href="#.">Software</a></li>
                </ul>
              </div>

              <div className="mil-divider mil-mb-60"></div>

              <div className="mil-mb-60">
                <h5 className="mil-list-title mil-mb-30">Tags</h5>

                <ul className="mil-tags">
                  <li><a href="#.">Business</a></li>
                  <li><a href="#.">Design</a></li>
                  <li><a href="#.">General</a></li>
                  <li><a href="#.">Development</a></li>
                  <li><a href="#.">Learning</a></li>
                </ul>
              </div>

              <div className="mil-divider mil-mb-60"></div>

              <h5 className="mil-list-title mil-mb-30">Contact us</h5>
              <p className="mil-mb-30">
                Interested in learning more?<br />
                Contact us today for a free consultation!
              </p>

              <a href="/contact" className="mil-link mil-link-sm"><span>Contact</span><i className="fas fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </section>

      <Toaster position="bottom-right" />

      <Suspense fallback={<Loader />}>
        <LazyFooter />
      </Suspense>
    </div>
  );
}
