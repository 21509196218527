import { useState, useEffect, Suspense, lazy } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import PartnerList from '../components/PartnerList';
import BreadcrumbHeader from '../components/BreadcrumbHeader';
import NavBar from '../components/NavBar';

import { titleize } from '../utils/strings';
import Loader from '../components/Loader';

const LazyFooter = lazy(() => import('../components/Footer'));
const LazyMixpanel = lazy(() => import('../components/MixPanel'));

export default function Service() {
  const { slug } = useParams();
  const navigate = useNavigate();

  const [, setLoading] = useState(true);
  const [service, setService] = useState<any>({});

  useEffect(() => {
    fetch(`/api/services?search=${slug}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setService(data);
          
        } else {
          navigate('/404');
        }

        setLoading(false);
      })
      .catch((_) => {
        navigate('/404');
        setLoading(false);
      });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <div className="mil-wrapper">
      <NavBar mode="page" />

      {(service) ? (
        <>
          <Suspense>
            <LazyMixpanel name={`Service Page, ${service.name}`} />
          </Suspense>

          <Helmet>
            <title>{`Pro Stack | ${titleize(slug)}`}</title>
            <link rel="canonical" href={window.location.href} />
          </Helmet>
        </>

      ) : null}

      <BreadcrumbHeader
        title={service?.name ?? slug}
        breadcrumbs={[
          { title: 'Home', url: '/' },
          { title: service?.name ?? titleize(slug), url: `/services/${slug}` }
        ]} />
      
      <section className="mil-p-120-90">
        <div className="mil-deco" style={{ bottom: 0, right: '25%', transform: 'rotate(180deg)' }}></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="mil-hori-box mil-mb-30">
                <div className="mil-mr-30">
                  <div className="mil-icon-frame mil-icon-frame-md mil-icon-bg">
                    <img src="../img/icons/md/1.svg" alt="icon" />
                  </div>
                </div>

                <h5>{service?.description}</h5>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="mil-adaptive-right">
                <a href="/contact" className="mil-button mil-border mil-mb-30">
                  <span>Get in Touch</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="mil-divider"></div>
      </div>

      <section className="mil-p-120-90">
        <div className="mil-deco" style={{ bottom: 0, right: '35%', transform: 'rotate(180deg)' }}></div>
        <div className="container">
          <div className="row">
            <div className="col-12 mil-mb-90">
              <span className="mil-suptitle mil-suptitle-2 mil-mb-30">
                Services and Solutions
              </span>

              <h2 className="mil-mb-30">
                {`Our ${service?.name} Services Let You Win Big`}
              </h2>

              <p className="mil-dark">
                {service?.solutions?.content}
              </p>
            </div>
          </div>

          <div className="row mil-mb-30-adapt">
            {service?.solutions?.points.map((point: any, index: any) => (
              <div className="col-xl-4" key={index}>
                <div className="mil-mb-60">
                  <div className="mil-number-icon mil-circle mil-mb-30">
                    <span>0{index + 1}</span>
                  </div>

                  <h4 className="mil-mb-15">{point.title}</h4>
                  <p>{point.description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="row align-items-center">
            <div className="col-md-6 col-xl-6">
              <a href="/contact" className="mil-button mil-border mil-mb-30"
                ><span>Tell us about your project</span></a
              >
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="mil-divider"></div>
      </div>

      <section className="mil-p-120-0">
        <div className="container">
          <div className="mil-text-center mil-mb-90">
            <span className="mil-suptitle mil-suptitle-2 mil-mb-30">
              {`Modern ${service?.name} Solutions`}
            </span>

            <h2>Ready To Take The Next Step?</h2>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="mil-hover-card mil-mb-30">
                <h4 className="mil-mb-30">Schedule a Consultation</h4>
                <p className="mil-mb-30">
                  Want to see what we can do for you? Schedule a consultation
                  with our team so we can discuss your project and explore ways
                  that we can help you with your project!
                </p>

                <a href="/contact" className="mil-link"
                  ><span>Request a Consultation</span
                  ><i className="fas fa-arrow-right"></i
                ></a>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="mil-hover-card mil-mb-30">
                <h4 className="mil-mb-30">Start Now</h4>
                <p className="mil-mb-30">
                  Ready to get started? Get in touch today with some information
                  about your project so we can help you take the next step
                  towards project success!
                </p>

                <a href="/contact" className="mil-link"
                  ><span>Get In Touch</span><i className="fas fa-arrow-right"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <PartnerList />

      <div className="container">
        <div className="mil-divider"></div>
      </div>

      <section className="mil-services mil-p-120-90">
        <div className="mil-deco" style={{ bottom: 0, right: '40%', transform: 'rotate(180deg)' }}></div>
        <div className="container">
          <span className="mil-suptitle mil-suptitle-2 mil-mb-30">
            High Quality and Performance
          </span>

          <h2 className="mil-mb-90">
            Our Approach To <span className="mil-accent">{service?.name}</span>
          </h2>

          <div className="row mil-mb-30-adapt">
            {service?.approach?.points.map((point: any, index: any) => (
              <div key={index} className="col-lg-6 col-xl-6">
                <div className="mil-service-item mil-without-lines mil-mb-60">
                  <div className="mil-service-icon">
                    <div className="mil-icon-frame mil-icon-frame-md">
                      <img src={point.icon} alt="icon" />
                    </div>
                  </div>

                  <div className="mil-service-text">
                    <h5 className="mil-mb-30">
                      <span className="mil-accent">{`0${index + 1}`}</span> {point.title}
                    </h5>

                    <p>{point.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="row align-items-center">
            <div className="col-md-6 col-xl-6">
              <a href="/contact" className="mil-button mil-border mil-mb-30">
                <span>{`Talk To Our ${service?.name} Experts`}</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      
      <section className="mil-gradient-bg mil-deco-right mil-p-120-0">
        <div className="mil-deco mil-deco-accent" style={{ top: 0, left: '5%' }}></div>
        <div className="container">
          <div className="mil-text-center mil-mb-90">
            <span className="mil-suptitle mil-light mil-suptitle-2 mil-mb-30">
              Always The Best
            </span>

            <h2 className="mil-light mil-mb-30">
              <span className="mil-accent">{`${service?.name} Services`}</span> We Deliver
            </h2>

            <p className="mil-light-soft">{service?.samples?.description}</p>
          </div>

          <div className="row mil-mb-30-adapt">
            {service?.samples?.points.map((point: any, index: any) => (
              <div key={index} className="col-lg-3">
                <div className="mil-icon-box-head mil-long mil-mob-center mil-mb-60">
                  <div className="mil-icon-frame mil-icon-frame-sm mil-light">
                    <img src={point.icon} alt="icon" />
                  </div>

                  <p className="mil-light">{point.title}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="mil-text-center">
            <a href="/contact" className="mil-button mil-border mil-light mil-mb-30">
              <span>{`Talk To Our ${service?.name} Experts`}</span>
            </a>
          </div>
        </div>
      </section>
      
      <Suspense fallback={<Loader />}>
        <LazyFooter />
      </Suspense>
    </div>
  );
}
