import { Suspense, lazy, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import BreadcrumbHeader from '../../components/BreadcrumbHeader';
import NavBar from '../../components/NavBar';
import Loader from '../../components/Loader';

const LazyFooter = lazy(() => import('../../components/Footer'));
const LazyMixpanel = lazy(() => import('../../components/MixPanel'));

export default function PortfolioIndividual() {
  const { slug } = useParams();
  const navigate = useNavigate();

  const [, setLoading] = useState(true);
  const [project, setProject] = useState<any>({});

  useEffect(() => {
    fetch(`/api/projects?search=${slug}`)
      .then((response) => response.json())
      .then((data) => {
        if ((data) && 
            (data.projects) && 
            (data.projects.length > 0)) {
              
          setProject(data.projects[0]);
          
        } else {
          navigate('/404');
        }

        setLoading(false);
      })
      .catch((_) => {
        navigate('/404');
        setLoading(false);
      });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mil-wrapper">
  
      {(project.name) ? (
        <>
          <Suspense>
            <LazyMixpanel name={`Portfolio Page, ${project.name}`} />
          </Suspense>

          <Helmet>
            <title>{`Pro Stack | ${project.name}`}</title>
            <link rel="canonical" href={window.location.href} />
          </Helmet>
        </>

      ) : null}

      <NavBar mode='page' />

      <BreadcrumbHeader
        title={project?.name}
        breadcrumbs={[
          { title: 'Home', url: '/' },
          { title: 'Portfolio', url: '/portfolio' },
          { title: project?.name, url: `/portfolio/${project?.link}` }
        ]} />
      
      <section className="mil-p-120-90">
        <div className="container">
          <div className="row flex-sm-row-reverse justify-content-between">
            <div className="col-lg-4 col-xl-3">
              <div className="mil-project-info mil-mb-60">
                <h5 className="mil-list-title mil-mb-30">Project Info</h5>

                <p className="mil-mb-10">Client</p>
                <h6 className="mil-mb-15">{project?.client}</h6>
                <div className="mil-divider mil-divider-left mil-mb-30"></div>

                <p className="mil-mb-10">Service</p>
                <h6 className="mil-mb-15">
                  {project?.services?.join(', ')}
                </h6>
                <div className="mil-divider mil-divider-left mil-mb-30"></div>

                <p className="mil-mb-10">Industry</p>
                <h6 className="mil-mb-15">{project?.industry}</h6>
                <div className="mil-divider mil-divider-left mil-mb-30"></div>

                <p className="mil-mb-10">Stack</p>
                <h6>{project?.stack?.join(', ')}</h6>
              </div>
            </div>

            <div className="col-lg-7 col-xl-8">
              <span className="mil-suptitle mil-suptitle-2 mil-mb-30">Overviews</span>
              <h3 className="mil-mb-30">{project?.paragraph1?.title}</h3>
              <p className="mil-mb-60">
                {project?.paragraph1?.content}
              </p>

              <h3 className="mil-mb-30">{project?.paragraph2?.title}</h3>
              <p className="mil-mb-60">
                {project?.paragraph2?.content}
              </p>

              <div className="row">
                <div className="col-xl-6">
                  <ul className="mil-check-icon-list mil-mb-15">
                    {(project?.keyPoints?.map((point: any, index: any) => (
                      <li key={index}>
                        <img src="../img/icons/sm/12.svg" alt="icon" />
                        <span className="mil-dark">{point}</span>
                      </li>
                    )))}
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="mil-divider"></div>
      </div>

      <section className="mil-p-120-60">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-5 mil-mb-60">
              <h3 className="mil-mb-30">{project?.paragraph3?.title}</h3>
              <p className="mil-mb-30">
                {project?.paragraph3?.content}
              </p>

              {(project?.paragraph3?.points?.map((point: any, index: any) => (
                <div key={index} className="mil-icon-box-head mil-long mil-mb-15">
                  <div className="mil-icon-frame mil-icon-frame-sm">
                    <img src={point.icon} alt={`${point.description} icon`} />
                  </div>

                  <p className="mil-dark">{point.description}</p>
                </div>
              )))}
            </div>

            <div className="col-xl-6 mil-mb-60">
              <div className="mil-project-cover">
                <img src={project?.paragraph3?.image} alt={`${project?.name} key`} />
              </div>
            </div>
          </div>

          <div className="row flex-sm-row-reverse justify-content-between align-items-center">
            <div className="col-xl-5 mil-mb-60">
              <h3 className="mil-mb-30">{project?.paragraph4?.title}</h3>
              <p className="mil-mb-30">
                {project?.paragraph4?.content}
              </p>

              {(project?.paragraph4?.points?.map((point: any, index: any) => (
                <div key={index} className="mil-icon-box-head mil-long mil-mb-15">
                  <div className="mil-icon-frame mil-icon-frame-sm">
                    <img src={point.icon} alt={`${point.description} icon`} />
                  </div>

                  <p className="mil-dark">{point.description}</p>
                </div>
              )))}
            </div>

            <div className="col-xl-6 mil-mb-60">
              <div className="mil-project-cover">
                <img src={project?.paragraph4?.image} alt={`${project?.name} key`} />
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <div className="container">
        <div className="mil-divider"></div>
      </div>

      <section className="mil-p-120-60">
        <div className="mil-deco" style={{ top: 0, left: '30%' }}></div>
        <div className="container">
          <div className="row align-items-end mil-mb-90">
            <div className="col-xl-6">
              <h2 className="mil-mb-30">Conclusion</h2>
              <p>{project?.conclusion?.content}</p>
            </div>
          </div>

          <div className="row">
            {project?.conclusion?.points?.map((point: any, index: any) => (
              <div key={index} className="col-md-6 col-xl-4">
                <div className="mil-icon-box-2 mil-mb-60">
                  <div className="mil-icon-frame mil-icon-frame-md mil-icon-bg mil-mb-30">
                    <img src={point.icon} alt={`${point.description} icon`} />
                  </div>

                  <div className="mil-box-text">
                    <h4 className="mil-mb-30">{point.title}</h4>
                    <p className="mil-box-text mil-mb-30">
                      {point.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Suspense fallback={<Loader />}>
        <LazyFooter />
      </Suspense>
    </div>
  );
}
