import mixpanel from 'mixpanel-browser';

mixpanel.init("f3658613c3a48328151b105acff648b2");

const env_check: any = process.env.NODE_ENV === 'production';

const actions = {
    identify: (id: any) => {
        if (env_check) mixpanel.identify(id)
    },
    alias: (id: any) => {
        if (env_check) mixpanel.alias(id)
    },
    track: (name: any, props: any) => {
        if (env_check) mixpanel.track(name, props)
    },
    people: {
        set: (props: any) => {
            if (env_check) mixpanel.people.set(props)
        },
    },
}

export const Mixpanel = actions;