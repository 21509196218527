import { Suspense, lazy, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Loader from '../../components/Loader';
import BreadcrumbHeader from '../../components/BreadcrumbHeader';
import NavBar from '../../components/NavBar';
import toast, { Toaster } from 'react-hot-toast';

const LazyFooter = lazy(() => import('../../components/Footer'));
const LazyMixpanel = lazy(() => import('../../components/MixPanel'));

export default function Team() {
  const [loading, setLoading] = useState(true);
  const [team, setTeam] = useState<any[]>([]);

  useEffect(() => {
    fetch('/api/users/team')
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setTeam(data.team);
          setLoading(false);

        } else {
          toast.error(`Failed to decode Team`);
        }

      })
      .catch((err) => {
        toast.error(`Failed to fetch Team`);
        console.log('Failed to fetch team', err);
      });

  }, []);
  
  return (
    <div className="mil-wrapper">
      <Suspense>
        <LazyMixpanel name='Team Page' />
      </Suspense>

      <Helmet>
        <title>{`Pro Stack | Team`}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      
      <NavBar mode='page' />
      <BreadcrumbHeader
        title='Our Team'
        breadcrumbs={[
          { title: 'Home', url: '/' },
          { title: 'Team', url: '/team' }
        ]} />
      
      <section className="mil-team mil-p-120-60">
        <div className="container">
          <h3 className="mil-text-center mil-mb-120">
            Meet our team of experts, with over <br /><span className="mil-accent">80 years</span> combined experience
          </h3>

          <div className="row">
            {loading ? (
              <>
                {[...Array(6)].map((_, index) => (
                  <div key={index} className="col-sm-6 col-lg-4">
                    <div className="mil-team-card mil-mb-60">
                      <div className="mil-image-frame mil-mb-30">
                        <Loader 
                          style={{ 
                            filter: 'grayscale(100%)',
                            borderRadius: '50%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%',
                            height: '100%',
                            transition: '0.4s cubic-bezier(0, 0, 0.3642, 1)'
                          }} />

                        <div className="mil-team-circle"></div>
                      </div>

                      <h4 className="mil-mb-10">{`Loading Expert ${index + 1}`}</h4>
                      <p>United States</p>
                      <p>Senior Software Developer</p>
                    </div>
                  </div>
                ))}
              </>

            ) : (
              <>
                {team.map((member, index) => (
                  <div key={index} className="col-sm-6 col-lg-4">
                    <a href={`team/${member.link}`} className="mil-team-card mil-mb-60">
                      <div className="mil-image-frame mil-mb-30">
                        <img src={member.image} alt={`${member.name}`} />
                        <div className="mil-team-circle"></div>
                      </div>

                      <h4 className="mil-mb-10">{member.name}</h4>
                      <p>{member.state}</p>
                      <p>{member.title}</p>
                    </a>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </section>

      <div className="container">
        <div className="mil-divider"></div>
      </div>

      <section className="mil-icon-boxes mil-p-120-60">
        <div className="container">
          <div className="row align-items-center justify-content-between mil-mb-90">
            <div className="col-xl-6">
              <h2>Values that <span className="mil-accent">Lead Us</span></h2>
            </div>

            <div className="col-xl-5">
              <p>
                At Pro Stack, our guiding principles are rooted in integrity,
                innovation, and a relentless pursuit of excellence, shaping
                every decision and project we undertake.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-xl-3">
              <div className="mil-icon-box mil-center mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-md mil-mb-30">
                  <img src="/img/icons/md/5.svg" alt="icon" />
                </div>

                <h5 className="mil-mb-20">
                  <span className="mil-accent">01.</span>&nbsp; Productivity
                </h5>
                <p>
                  Pro Stack prioritizes efficiency and effectiveness, ensuring
                  maximum productivity in delivering solutions that make a real
                  difference.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <div className="mil-icon-box mil-center mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-md mil-mb-30">
                  <img src="/img/icons/md/5.svg" alt="icon" />
                </div>

                <h5 className="mil-mb-20">
                  <span className="mil-accent">02.</span>&nbsp; Transparency
                </h5>
                <p>
                  Transparency is key at Pro Stack, fostering trust and open
                  communication with clients throughout every project phase.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <div className="mil-icon-box mil-center mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-md mil-mb-30">
                  <img src="/img/icons/md/5.svg" alt="icon" />
                </div>

                <h5 className="mil-mb-20">
                  <span className="mil-accent">03.</span>&nbsp; Personality
                </h5>
                <p>
                  {"Our team's unique blend of personalities fuels our creative approach and collaborative spirit, making innovation second nature."}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-xl-3">
              <div className="mil-icon-box mil-center mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-md mil-mb-30">
                  <img src="/img/icons/md/5.svg" alt="icon" />
                </div>

                <h5 className="mil-mb-20">
                  <span className="mil-accent">04.</span>&nbsp; Volition
                </h5>
                <p>
                  At Pro Stack, our strong volition drives us to take
                  initiative, tackle challenges head-on, and continuously strive
                  for improvement and success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="call-to-action mil-gradient-bg mil-p-120-0">
        <div className="mil-deco mil-deco-accent" style={{ top: 0, left: '15%' }}></div>
        <div className="container mil-text-center">
          <div className="mil-cta-frame">
            <div className="mil-icon-frame mil-icon-frame-md mil-mb-60">
              <img src="/img/icons/md/6l.svg" alt="icon" />
            </div>

            <p className="mil-light mil-mb-30">Leadership Team</p>
            <h2 className="mil-light mil-mb-30">
              Let’s <span className="mil-accent">Open the World</span> of IT to You
            </h2>
            <p className="mil-light-soft mil-mb-60">
              Dive into the limitless possibilities of IT with Pro Stack; book
              now to embark on a <br />
              journey of innovation, tailored solutions, and transformative
              success.
            </p>
            <a href="/contact" className="mil-button mil-border mil-light"
              ><span>Book an Appointment</span></a
            >
          </div>
        </div>
      </section>
      
      <Toaster position="bottom-right" />

      <Suspense fallback={<Loader />}>
        <LazyFooter />
      </Suspense>
    </div>
  );
}
