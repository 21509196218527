interface Props {
  title: string
  breadcrumbs: { title: string, url: string }[]
}

export default function BreadcrumbHeader({ title, breadcrumbs }: Props) {
  return (
    <div className="mil-banner-sm mil-deep-bg">
      <img
        src="/img/deco/map.png"
        alt="background"
        className="mil-background-image" />

      <picture>
        <source type="image/webp" srcSet="/img/deco/map.webp" className="mil-background-image" />
        <source type="image/png" srcSet="/img/deco/map.png" className="mil-background-image" />
        <img src="/img/deco/map.png" alt="background" className="mil-background-image" />
      </picture>

      <div className="mil-deco mil-deco-accent" style={{ top: '47%', right: '10%', transform: 'rotate(90deg)' }}></div>
      <div className="mil-banner-content">
        <div className="container mil-relative">
          <ul className="mil-breadcrumbs mil-mb-30">
            {breadcrumbs.map((breadcrumb, index) => (
              <li key={index}>
                <a href={breadcrumb.url}>{breadcrumb.title}</a>
              </li>
            ))}
          </ul>

          <h2 className="mil-uppercase">{title}</h2>
        </div>
      </div>
    </div>
  )
}